import { UserManagerSettings } from "oidc-client";

export const environmentUat = {
  production: false,
  APP_BASE_URL: "./../../../assets/data/",
  dashboardProspectApiURL: 'https://uat-prospectapi.lrsphub.com/',
  contactApiURL: 'https://uat-contactapi.lrsphub.com/',
  campaignApiURL: 'https://uat-campaignapi.lrsphub.com/',
  messageHistoryApiURL: 'https://uat-messagehistoryapi.lrsphub.com/',
  eventApiURL: 'https://uat-eventapi.lrsphub.com/',
  responsesettingEventEditApiURL: 'https://uat-responsesettingsapi.lrsphub.com/',
  integrationExportsApiURL: 'https://uat-integrationandexportsapi.lrsphub.com/',
  OneLoginApiURL: 'https://uat-Oneloginsso-env.lrsphub.com/',
  achSuiteletURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&ns-at=AAEJ7tMQMVycW3-EnDdZvjnksiBEtaHZ4nfx6C-3EaRwviXN8q4&paytype=achresponse',
  achFormURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&ns-at=AAEJ7tMQMVycW3-EnDdZvjnksiBEtaHZ4nfx6C-3EaRwviXN8q4&paytype=customachform',
  hubCommunicationApis: 'https://uat-sendingmail-awsses-api.lrsphub.com/',
  uspsPostalStatement: 'https://uspscsvdata-nonprod.lrsphub.com/',
  authUserName: "admin",
  authPassword: "Rk2iK4tZm5I3",
  OneloginTimeout: '1800000',
};

export const clientSettingsUAT: UserManagerSettings = {
  authority: 'https://leadingresponse.onelogin.com/oidc/2',
  client_id: '17f680c0-1384-013c-ab8f-72e16f159c66192627',
  redirect_uri: 'https://uat.lrsphub.com/auth-callback',
  post_logout_redirect_uri: 'https://leadingresponse.onelogin.com/login2',
  response_type: "id_token token",
  scope: "openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true
}

export const PaymentURLUAT: string = "https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_lrpayprocess&deploy=customdeploy_lrpayprocess&compid=1014080_SB1&h=ed12ad571b8e6e4ab424&tranno=<Salesorderno#Invoice#>&trantype=4";