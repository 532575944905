import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { map, delay, catchError, tap } from 'rxjs/operators';
import { AppConstants, UrlConstants } from '../constants/app.constants';
import { clientSettings, environment, PaymentURL } from '../../../environments/environment';
import { clientSettingsDev, environmentDev } from '../../../environments/environment.dev';
import { clientSettingsQA, environmentQa, PaymentURLQA } from '../../../environments/environment.qa';
import { clientSettingsProd, environmentProd, PaymentURLProd } from '../../../environments/environment.prod';
import * as moment from 'moment';
import { clientSettingsUAT, environmentUat } from '../../../environments/environment.uat';

@Injectable()
export class SharedService {

  //PCNG-2259 This metnod is to inject the state of "add Prospect" button
  //on the basis of comparing today's date with event date + 5 days.
  public getProspectButtonState(mergeEventdetails:any) {
    let _mergeEventdetails;
    return _mergeEventdetails = mergeEventdetails.map(item => {
      let showAddButton;
      let eventDatePlusFive = moment(item.date, AppConstants.PROSPECT_DATE_FORMAT).add('d', AppConstants.PROSPECT_DAYS_ADD);
      let currentDate = moment().format(AppConstants.PROSPECT_DATE_FORMAT);

      //In case current date is less than or equal to eventDate + 5 days
      if (currentDate <= eventDatePlusFive.format(AppConstants.PROSPECT_DATE_FORMAT)) {
        showAddButton = true
      } 
      //In case current date is greater than eventDate + 5 days
      else if (currentDate > eventDatePlusFive.format(AppConstants.PROSPECT_DATE_FORMAT)) {
        showAddButton = false
      }
      //return a new object for an array
      return { ...item, toggleAddProspectButton: showAddButton };
    })
  }

  //This will store the reference data values.
  public menuCollapseFlag: boolean = false;
  /**
   * Default Constructer Invoked.
   */
  constructor(private http: HttpClient) { }

  public setMenuFlag(flag) {
    this.menuCollapseFlag = flag;
  }

  /**
   * Function Called to get all reference data from web service.
   */
  public getMenuFlag() {
    return this.menuCollapseFlag;
  }


  private handleError(error) {
    return throwError(error);
  }

  baseUrl = '';

  public detectEnv_ParamOneLogin() {
    this.baseUrl = window.location.origin;
    const host = this.baseUrl.split('/');
    const checkURL = host[2];
    const takeParts = checkURL.split('-');
    const urlParts = takeParts[0];
    return this.detectEnvOneLogin(urlParts);
  }

  public splitCurrentURL(apiurlName) {
    this.baseUrl = window.location.origin;
    const host = this.baseUrl.split('/');
    const checkURL = host[2];
    const takeParts = checkURL.split('-');
    const urlParts = takeParts[0];
    return this.checkDev(urlParts, apiurlName);
  }

  envValue = '';
  checkDev(urlParts, apiurlName) {
    this.envValue = urlParts;

    if (this.envValue == 'devpcnxtgen' || this.envValue == 'devpcnextgen.leadingresponse.com' || this.envValue == 'dev.lrsphub.com') {
      switch (apiurlName) {
        case 1:
          return environmentDev.dashboardProspectApiURL;
        case 2:
          return environmentDev.contactApiURL;
        case 3:
          return environmentDev.campaignApiURL;
        case 4:
          return environmentDev.messageHistoryApiURL;
        case 5:
          return environmentDev.eventApiURL;
        case 6:
          return environmentDev.responsesettingEventEditApiURL;
        case 7:
          return environmentDev.integrationExportsApiURL;
        case 8:
          return environmentDev.OneLoginApiURL;
        case 9:
          return environmentDev.OneloginTimeout;
        case 10:
          return environmentDev.achSuiteletURL;
        case 11:
          return environmentDev.achFormURL;
        case 12:
          return environmentDev.uspsPostalStatement;
        case 13:
          return environmentDev.hubCommunicationApis          
        default:
          return null;
      }
    }
    else if (this.envValue.toLocaleLowerCase().includes("qa") || this.envValue == 'pcnextgenqa' || this.envValue == 'qapcnextgen.leadingresponse.com' || this.envValue == 'qa.lrsphub.com') {
      switch (apiurlName) {
        case 1:
          return environmentQa.dashboardProspectApiURL;
        case 2:
          return environmentQa.contactApiURL;
        case 3:
          return environmentQa.campaignApiURL;
        case 4:
          return environmentQa.messageHistoryApiURL;
        case 5:
          return environmentQa.eventApiURL;
        case 6:
          return environmentQa.responsesettingEventEditApiURL;
        case 7:
          return environmentQa.integrationExportsApiURL;
        case 8:
          return environmentQa.OneLoginApiURL;
        case 9:
          return environmentQa.OneloginTimeout;
        case 10:
          return environmentQa.achSuiteletURL;
        case 11:
          return environmentQa.achFormURL;
        case 12:
          return environmentQa.uspsPostalStatement;
        case 13:
          return environmentQa.hubCommunicationApis          
        default:
          return null;
      }
    }
    else if (this.envValue.toLocaleLowerCase().includes("uat") || this.envValue == 'pcnextgenuat' || this.envValue == 'uatpcnextgen.leadingresponse.com' || this.envValue == 'uat.lrsphub.com') {
      switch (apiurlName) {
        case 1:
          return environmentUat.dashboardProspectApiURL;
        case 2:
          return environmentUat.contactApiURL;
        case 3:
          return environmentUat.campaignApiURL;
        case 4:
          return environmentUat.messageHistoryApiURL;
        case 5:
          return environmentUat.eventApiURL;
        case 6:
          return environmentUat.responsesettingEventEditApiURL;
        case 7:
          return environmentUat.integrationExportsApiURL;
        case 8:
          return environmentUat.OneLoginApiURL;
        case 9:
          return environmentUat.OneloginTimeout;
        case 10:
          return environmentUat.achSuiteletURL;
        case 11:
          return environmentUat.achFormURL;
        case 12:
          return environmentUat.uspsPostalStatement;
        case 13:
          return environmentUat.hubCommunicationApis          
        default:
          return null;
      }
    }
    else if (this.envValue.indexOf('localhost') >= 0) {
      switch (apiurlName) {
        case 1:
          return environment.dashboardProspectApiURL;
        case 2:
          return environment.contactApiURL;
        case 3:
          return environment.campaignApiURL;
        case 4:
          return environment.messageHistoryApiURL;
        case 5:
          return environment.eventApiURL;
        case 6:
          return environment.responsesettingEventEditApiURL;
        case 7:
          return environment.integrationExportsApiURL;
        case 8:
          return environment.OneLoginApiURL;
        case 9:
          return environment.OneloginTimeout;
        case 10:
          return environment.achSuiteletURL;
        case 11:
          return environment.achFormURL;
        case 12:
          return environment.uspsPostalStatement;
        case 13:
          return environment.hubCommunicationApis          
        default:
          return null;
      }
    }

    else if (this.envValue.indexOf('lrsphub.com') >= 0) {
      switch (apiurlName) {
        case 1:
          return environmentProd.dashboardProspectApiURL;
        case 2:
          return environmentProd.contactApiURL;
        case 3:
          return environmentProd.campaignApiURL;
        case 4:
          return environmentProd.messageHistoryApiURL;
        case 5:
          return environmentProd.eventApiURL;
        case 6:
          return environmentProd.responsesettingEventEditApiURL;
        case 7:
          return environmentProd.integrationExportsApiURL;
        case 8:
          return environmentProd.OneLoginApiURL;
        case 9:
          return environmentProd.OneloginTimeout;
        case 10:
          return environmentProd.achSuiteletURL;
        case 11:
          return environmentProd.achFormURL;
        case 12:
          return environmentProd.uspsPostalStatement;
        case 13:
          return environmentProd.hubCommunicationApis          
        default:
          return null;
      }
    }
  }

  detectEnvOneLogin(urlParts) {

    if (urlParts == 'devpcnxtgen' || urlParts == 'devpcnextgen.leadingresponse.com' || urlParts == 'dev.lrsphub.com') {
      return clientSettingsDev;
    }
    else if (urlParts == 'pcnextgenqa' || urlParts == 'qapcnextgen.leadingresponse.com' || urlParts == 'qa.lrsphub.com') {
      return clientSettingsQA;
    }
    else if (urlParts == 'pcnextgenuat' || urlParts == 'uatpcnextgen.leadingresponse.com' || urlParts == 'uat.lrsphub.com') {
      return clientSettingsUAT;
    }
    else if (urlParts.indexOf('localhost') >= 0) {
      return clientSettings;
    }
    else if (urlParts.indexOf('lrsphub.com') >= 0) {
      return clientSettingsProd;
    }
  }

  detectPaymentURL() {

    this.baseUrl = window.location.origin;
    const host = this.baseUrl.split('/');
    const checkURL = host[2];
    const takeParts = checkURL.split('-');
    const urlParts = takeParts[0];

    if (urlParts == 'devpcnxtgen' || urlParts == 'devpcnextgen.leadingresponse.com' || urlParts == 'dev.lrsphub.com') {
      return PaymentURL;
    }
    else if (urlParts == 'pcnextgenqa' || urlParts == 'qapcnextgen.leadingresponse.com' || urlParts == 'qa.lrsphub.com') {
      return PaymentURLQA;
    }
    else if (urlParts.indexOf('localhost') >= 0) {
      return PaymentURL;
    }
    else if (urlParts.indexOf('lrsphub.com') >= 0) {
      return PaymentURLProd;
    }
  }
}

export enum apiurlName {
  dashboardProspectApiEndpoint = 1,
  contactApiEndpoint = 2,
  campaignApiEndPoint = 3,
  messagehistoryApiEndpoint = 4,
  eventApiEndPoint = 5,
  responsesettingEventEditApiEndPoint = 6,
  integrationExportsApiEndPoint = 7,
  oneLoginApi = 8,
  OneloginTimeout = 9,
  achSuiteletURL = 10,
  achFormURL = 11,
  uspsPostalStatement = 12,
  hubCommunicationApis = 13
}

export enum MailTemplates {
  Test = -1,
  Reorder = 1,
  RequestAPIIntegration = 2,
  RequestDirect2Hub = 3,
}

